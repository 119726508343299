<template>
  <search-select
    v-model="selectedBand"
    :options="options"
    :filter-function="applySearchFilter"
  ></search-select>
</template>

<script>
import SearchSelect from '@/components/form/SearchSelect.vue'

export default {
  components: {
    SearchSelect
  },
  props: {
    options: { type: Array }
  },
  data() {
    return {
      selectedBand: null,
      search: '',
      // bands: [
      //   'Anthrax',
      //   'Dark Angel',
      //   'Death Angel',
      //   'Destruction',
      //   'Exodus',
      //   'Flotsam and Jetsam',
      //   'Kreator',
      //   'Megadeth',
      //   'Metallica',
      //   'Overkill',
      //   'Sepultura',
      //   'Slayer',
      //   'Testament',
      // ]
    }
  },
  methods: {
    applySearchFilter(search, bands) {
      return bands.filter(band => band.toLowerCase().startsWith(search.toLowerCase()))
    }
  }
}
</script>
